.container {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width:575px) {
  .container{
    max-width: 364px
  }
}

@media (min-width:576px) {
  .container{
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1140px
  }
}

@media (min-width:1536px) {
  .container {
    max-width: 1320px
  }
}

@font-face {
  font-family: blackBubble;
  src: url(black-bubble.ttf);
}

.egg-menu-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
}

.title-bar {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 10px 0;
  /* background-color: white; */
}

.title-bar .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 780px) {
  .title-bar .content {
    flex-direction: column;
  }
}

.menu-bar {
  display: flex;
  align-items: center;
  height: 50px;
  /* background-color: white; */
  border: 1px solid darkgray;
  border-width: 1px 0 1px 0;
}

.menu-bar .content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  /* color: white; */
  margin: 0 15px;
  transition: all 0.25s;
  cursor: pointer;
  transform: scale(0.9);
  color: rgb(29, 162, 231);
  white-space: nowrap;
}

.menu-item.active {
  transform: scale(1.2);
}

.menu-item:hover {
  transform: scale(1.2);
}

.menu-bar .menu-item.disabled {
  color: grey;
  pointer-events: none;
}

.main-content {
  display: flex;
  width:  100%;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  flex-grow: 1;
  /* background: white; */
}